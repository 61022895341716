import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import ReactInlineSvg from 'react-inlinesvg';
import { useSelector } from 'react-redux';
import { ButtonTypes } from '../../elements';
import { Auth } from '../Auth/Auth';
import { translateField } from '../../shared/utils';
import CustomWheel from './wheel/Wheel';
import Chest from './chest/Chest';

export const wheelData = [
  { option: '75 SPINS', style: { backgroundColor: '#7a0000' } },
  { option: `DEPOSIT+100%`, style: { backgroundColor: '#151520' } },
  { option: '100 FREE SPINS', style: { backgroundColor: '#7a0000' } },
  { option: 'TRY AGAIN', style: { backgroundColor: '#151520' } },
  { option: '50 SPINS', style: { backgroundColor: '#7a0000' } },
  { option: 'TRY AGAIN', style: { backgroundColor: '#151520' } },
  { option: '25 SPINS', style: { backgroundColor: '#7a0000' } },
  { option: 'TRY AGAIN', style: { backgroundColor: '#151520' } },
];

const ALL_ATTEMPTS = 2;
const USED_ATTEMPTS = localStorage.getItem('prizeIndexes') ? JSON.parse(localStorage.getItem('prizeIndexes')).length : 0;
const HAS_ATTEMPTS = ALL_ATTEMPTS - USED_ATTEMPTS;

export const Roulette = () => {
  const [attemptCount, setAttemptCount] = useState(HAS_ATTEMPTS);
  const [prizeIndex, setPrizeIndex] = useState(0);
  const [prize, setPrize] = useState(null);
  const [showRegBlock, setShowRegBlock] = useState(true);
  const words = useSelector((state) => state.words);
  const settings = useSelector((state) => state.settings);
  const size = useSelector((state) => state.handling.size);

  const addPrizeIndexToLocalStorage = (index) => {
    const prizeIndexesArray = localStorage.getItem('prizeIndexes') ? JSON.parse(localStorage.getItem('prizeIndexes')) : [];
    localStorage.setItem('prizeIndexes', JSON.stringify([...prizeIndexesArray, index]));
  };
  const prizeIndexes = localStorage.getItem('prizeIndexes');
  const bonuses = prizeIndexes ? JSON.parse(prizeIndexes).map((number) => wheelData[number]) : [];

  const [sizeWidthRoullette, setSizeWidthRoullette] = useState(window.innerWidth);
  const [sizeHeightRoullette, setSizeHeightRoullette] = useState(window.innerHeight);

  useEffect(() => {
    localStorage.setItem('prizeIndexes', JSON.stringify([1, 2]));
  }, []);

  // Set initial dimensions immediately
  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      const minDimension = Math.min(newWidth, newHeight);

      setSizeWidthRoullette(minDimension);
      setSizeHeightRoullette(minDimension);
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Initialize sizes on component mount
    handleResize();

    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures this runs only on mount

  return (
    <div
      className={classNames('roulette-section', {
        'roulette-section--show-form': showRegBlock,
      })}
      style={
        {
          // height: size.height,
        }
      }
    >
      {showRegBlock && (
        <div className="show-form">
          <img className="logo" src="/logo.png" alt="logo" />
          <div className="show-form_content">
            {bonuses.length > 0 && (
              <div className="roulette-section__bonus-in-form">
                <div className="title">{translateField('congratulations', 'roulette', words, false)}</div>
                <div className="title-win">{translateField('u_win_bonuses', 'roulette', words, false)}</div>
                <div className="bonus-in-form">
                  {bonuses.map(({ option }, i) => (
                    <div key={i} className="roulette-section__bonus item">
                      <span>{option}</span>
                    </div>
                  ))}
                </div>
                <div className="title-reg">{translateField('complete_registration', 'roulette', words, false)}</div>
              </div>
            )}
            <Auth />
          </div>
          <ButtonTypes className="roulette-section__link" location="roulette-section__link-site" onClick={() => window.open(settings.redirect, '_self')}>
            <span>{translateField('have_account', 'roulette', words, false)}</span>
            <span>{translateField('to_site', 'roulette', words, false)}</span>
            <ReactInlineSvg className="icon-arrow" src="/images/icons/arrow-right.svg" alt="to_login" width="10" height="10" />
          </ButtonTypes>
        </div>
      )}

      {!showRegBlock && (
        <>
          <div className="roulette-section__content">
            <img src="/images/wheel/chest_with_chips.png" alt="chest_with_chips" className="roulette-section__content__chest-with-chips" />

            <div
              className="roulette-section__content__spin"
              style={{
                width: size.mobile ? null : sizeWidthRoullette,
                height: size.mobile ? null : sizeHeightRoullette,

                maxWidth: size.mobile ? null : sizeWidthRoullette - 180,
                maxHeight: size.mobile ? null : sizeHeightRoullette - 180,
              }}
            >
              <CustomWheel
                prizeIndex={prizeIndex}
                attemptCount={attemptCount}
                data={wheelData}
                onStartSpinning={() => {
                  setAttemptCount(attemptCount - 1);
                  const nextIndex = prizeIndex % wheelData.length;
                  setPrizeIndex(nextIndex + 1);
                }}
                onStopSpinning={() => {
                  addPrizeIndexToLocalStorage(prizeIndex);

                  setTimeout(() => {
                    if (attemptCount === 0) {
                      setShowRegBlock(true);
                    }
                  }, 2000);
                  setPrize(wheelData[prizeIndex]);
                  wheelData[prizeIndex].style = { backgroundColor: '#7720AC' };
                }}
              />
            </div>
          </div>

          <div className="roulette-section__info">
            <ButtonTypes className="roulette-section__link" location="roulette-section__link-site" onClick={() => window.open(settings.redirect, '_self')}>
              <span>{translateField('have_account', 'roulette', words, false)}</span>
              <span>{translateField('to_site', 'roulette', words, false)}</span>
              <ReactInlineSvg className="icon-arrow" src="/images/icons/arrow-right.svg" alt="to_login" width="10" height="10" />
            </ButtonTypes>
            <Chest attemptCount={attemptCount} />

            <div
              className={classNames('roulette-section__win', {
                show: prize,
              })}
            >
              <div className="title">{translateField('u_win_bonus', 'roulette', words, false)}</div>
              <div className="roulette-section__bonus" style={!prize ? { opacity: 0 } : null}>
                <span>{prize?.option}</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
