import React from 'react';

import { Spinner } from '../Spinner/Spinner';

export const ButtonsEl = (props) => {
  let classNames = 'button';

  if (props.className) {
    classNames += ' ' + props.className;
  }

  return (
    <button
      type={props.type}
      className={classNames}
      data-state={props.state}
      data-loading={props.loading}
      data-disabled={props.disabled}
      data-hidden={props.hidden}
      onClick={props.onClick}
    >
      <div className="button__loader-text-wrapper">
        {props.loading && (
          <div className="button__spinner-wrapper">
            <Spinner />
          </div>
        )}
        {props.children && props.children}
      </div>
    </button>
  );
};
