import React from 'react';
import { useSelector } from 'react-redux';
import { translateField } from '../../../shared/utils';
import { ButtonTypes } from '../../../elements';

const Chest = ({ attemptCount }) => {
  const disabled = attemptCount === 0;
  const words = useSelector((state) => state.words);

  return (
    <ButtonTypes className="roulette-section__chest roulette-section__link" location="roulette-section__link-site" disabled={disabled} style={disabled ? { opacity: '40%' } : null}>
      <img src="/images/icons/chest.svg" alt="chest" />
      <div className="text">
        <span>{attemptCount}</span>
        <span>{translateField('attempts', 'roulette', words, true)}</span>
      </div>
    </ButtonTypes>
  );
};

export default Chest;
