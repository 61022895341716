import React from 'react';
import { useState } from 'react';
import { ChestList } from './ChestList/ChestList';

const ALL_ATTEMPTS = 2;
const USED_ATTEMPTS = 0;
const HAS_ATTEMPTS = ALL_ATTEMPTS - USED_ATTEMPTS;
export const ChestSelect = () => {
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [moving, setMoving] = useState(false);
  const [attemptCount, setAttemptCount] = useState(HAS_ATTEMPTS);
  // 0 - not selected
  // 1-10 - selected chest number

  const handleStart = () => {
    setPrizeNumber(Math.ceil(Math.random() * 10));
    setMoving(true);
  };

  return (
    <div className="chest_select-section">
      <div className="chest_select-section__showed">
        <img className="chest_select-section__showed__shadow" src="/images/chest_select/shadow.png" alt="shadow" />
        <img className="chest_select-section__showed__chest" src={`/images/chest_select/chest_${!moving ? prizeNumber : 0}.png`} alt="selected_chest" />
        <img className="chest_select-section__showed__light" src="/images/chest_select/light.png" alt="light" />
      </div>
      {attemptCount === ALL_ATTEMPTS ? (
        <div className="chest_select-section__description_first_time">
          <h2>беспроигрышная лотерея призов!</h2>
          <button disabled={moving} onClick={handleStart}>
            Старт
          </button>
        </div>
      ) : (
        <div className="chest_select-section__description_result">
          <div className="chest_select-section__description_result__prize">
            <p>Сундук название</p>
            <p>{!moving ? `Номер ${prizeNumber}` : 'Название'}</p>
            <img src="/images/chest_select/777.png" alt="777" />
          </div>
          <button disabled={moving} onClick={handleStart}>
            Прокрутить еще раз
          </button>
        </div>
      )}
      <div className="chest_select-section__footer">
        <p>Сундуки, которые могут выпасть:</p>
        <hr />
        <ChestList prizeNumber={prizeNumber} moving={moving} setMoving={setMoving} setAttemptCount={setAttemptCount} />
      </div>
    </div>
  );
};
