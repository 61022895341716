import React from 'react';
import styled from 'styled-components';

import Page from '../_Page/Page';
import { Layout } from '../_Layout/Layout';

const Title = styled.h1`
  font-size: 100px;
  font-weight: bold;
  color: #ff6666;
`;

const Message = styled.p`
  font-size: 24px;
  color: #fff;
`;

const HomeLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;

  p {
    margin: 0;
  }

  a {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4caf50;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-size: 18px;
    transition: background-color 0.3s ease;
    margin-left: 20px;
  }
`;

export const Error500 = ({ error }) => {
  const renderErrorCode = (error) => {
    switch (error) {
      case 'error-words':
        return 'Ошибка загрузки словаря';
      case 'error':
        return 'Ошибка загрузки данных';
      default:
        return 'Ошибка загрузки данных';
    }
  };

  return (
    <Page
      navigate={{
        header: false,
        banner: false,
        menu: false,
        filter: false,
        footer: false,
      }}
    >
      <Layout>
        <Title>{renderErrorCode(error)}</Title>
        <HomeLink>
          <Message>Попробуйте пожалуйста позже</Message>
          <a href="/" className="home-link">
            Go to Home
          </a>
        </HomeLink>
      </Layout>
    </Page>
  );
};
