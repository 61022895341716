export const SET_PAGE_METADATA = 'SET_PAGE_METADATA';

export const GET_WORDS = 'GET_WORDS';
export const SET_WORDS = 'SET_WORDS';
export const GET_WORDS_ERROR = 'GET_WORDS_ERROR';

export const SET_LOCAL_WORDS = 'SET_LOCAL_WORDS';

export const FETCH_DATA = 'FETCH_DATA';
export const UPDATE_DATA = 'UPDATE_DATA';
export const BONUS_DETAILS_INFO = 'BONUS_DETAILS_INFO';
export const FETCH_DATA_ERROR = 'UPDATE_DATA';

export const GET_WINDOW_SIZE = 'GET_WINDOW_SIZE';
export const GET_IP_SUCCESS = 'GET_IP_SUCCESS';
export const GET_IP_ERROR = 'GET_IP_ERROR';
export const GET_USERAGENT_SUCCESS = 'GET_USERAGENT_SUCCESS';

export const SIGNUP_TYPE = 'SIGNUP_TYPE';
export const SIGNUP_TYPE_SUCCESS = 'SIGNUP_TYPE_SUCCESS';
export const SIGNUP_PHONE_NUMBER = 'SIGNUP_PHONE_NUMBER';
export const SIGNUP_TYPE_ACTIVATION = 'SIGNUP_TYPE_ACTIVATION';
export const SIGNUP_TYPE_ERROR = 'SIGNUP_TYPE_ERROR';

export const GET_INFO = 'GET_INFO';
export const SET_INFO = 'SET_INFO';
export const GET_INFO_ERROR = 'GET_INFO_ERROR';

export const ACCOUNT_UPDATE_INFO = 'ACCOUNT_UPDATE_INFO';

export const SET_ACTIVE_INPUT = 'SET_ACTIVE_INPUT';

export const SET_ACTIVE_FORM = 'SET_ACTIVE_FORM';

export const GET_VERIVICATION_ERROR = 'SET_VERIVICATION';
export const SET_VERIVICATION = 'SET_VERIVICATION';
