import styled from 'styled-components';

export const Error = styled.div`
  margin-top: 4px;
  color: red;
`;

export const FieldInput = styled.div`
  input {
    &::placeholder {
      color: white;
    }
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px rgb(38, 38, 38) inset;
      transition: background-color 50000s ease-in-out 0s;
      -webkit-text-fill-color: white;
    }
  }
  .react-international-phone-input-container {
    border-radius: 50%;
    height: 45px;
    .react-international-phone-input,
    .react-international-phone-country-selector {
      height: 45px;
      background: rgba(255, 255, 255, 0.15);
      border: 0;
      color: #fff;
      margin: 0;
      > button {
        padding-left: 7px;
        height: 45px;
        background: rgba(255, 255, 255, 0.15);
        border: 1px solid rgba(255, 255, 255, 0.15);
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
      }
    }
    .react-international-phone-input {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
    .react-international-phone-country-selector {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }
  }
`;

export const Loader = styled.div`
  height: ${(props) => (props.height ? props.height : 'inherit')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BlockButton = styled.div``;
