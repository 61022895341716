import { GET_WINDOW_SIZE } from '../actions/actionTypes';

const windowSize = {
  width: window.innerWidth,
  height: window.innerHeight,
};

const initialState = {
  size: {
    width: windowSize.width,
    height: windowSize.height,
    mobile: isMobile(windowSize),
    landscape: isLandscape(windowSize),
    device: isMobile(windowSize) ? 'mobile' : 'desktop',
    orientation: isLandscape(windowSize) ? 'horizontal' : 'vertical',
    sliderContainer: null,
  },
};

function isMobile(param) {
  return param.width <= 992;
}

function isLandscape(a) {
  return a.width > a.height;
}

const handlingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WINDOW_SIZE:
      return {
        ...state,
        size: {
          ...action.payload,
          mobile: isMobile(action.payload),
          landscape: isLandscape(action.payload),
          device: isMobile(action.payload) ? 'mobile' : 'desktop',
          orientation: isLandscape(action.payload) ? 'horizontal' : 'vertical',
        },
      };
    default:
      return state;
  }
};

export default handlingReducer;
