import React from 'react';
import { Roulette } from '../../../components';

export const LandingFortuneWheel = () => {
  return (
    <>
      <Roulette />
    </>
  );
};
