import React, { useEffect, useMemo, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const Page = ({ meta, children, navigate, url = '' }) => {
  const headInfo = useSelector((state) => state.meta);
  // const googleAnalyticsId = useSelector((state) => state.api.modules?.googleAnalytics);
  const searchResult = useSelector((state) => state.searchResult);
  const currentPageURL = window.location.href;

  const location = useLocation();

  useLayoutEffect(() => {
    const ignorePage = ['provider', 'tag', 'category', 'games'];
    const path = location.pathname;

    const shouldScrollToTop = !ignorePage.some((page) => path.includes(page));

    if (shouldScrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const { mergedMeta } = useMemo(() => {
    const defaultMeta = {
      title: headInfo?.title,
      description: headInfo?.description,
      keywords: headInfo?.keywords,
      content: headInfo?.content,
    };

    const mergedMeta = { ...defaultMeta, ...meta };

    return { mergedMeta, defaultMeta };
  }, [headInfo, meta]);

  useEffect(() => {
    localStorage.setItem('meta', JSON.stringify(mergedMeta));
  }, [mergedMeta]);

  // useEffect(() => {
  //   // Ensure the gtag script is loaded
  //   const script = document.createElement('script');
  //   script.async = true;
  //   script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
  //   document.head.appendChild(script);

  //   script.onload = () => {
  //     window.dataLayer = window.dataLayer || [];
  //     function gtag() {
  //       window.dataLayer.push(arguments);
  //     }
  //     gtag('js', new Date());
  //     gtag('config', googleAnalyticsId);
  //   };
  //   return () => {
  //     document.head.removeChild(script);
  //   };
  // }, [googleAnalyticsId]);

  const viewFirstWord = (location) => {
    const pathname = location?.pathname;
    const firstWord = pathname?.split('/')[1];

    if (firstWord === undefined) return [];
    return ' ' + firstWord;
  };

  const firstWord = viewFirstWord(url);

  return (
    <div className={`view${firstWord}`}>
      <Helmet>
        <title>{mergedMeta.title}</title>
        <link rel="canonical" href={currentPageURL} />
        <meta name="description" content={mergedMeta.description} />
        <meta name="keywords" content={mergedMeta.keywords} />
        <meta content={mergedMeta.content} />
      </Helmet>
      {!Boolean(searchResult?.query) && children}
    </div>
  );
};

Page.propTypes = {
  meta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
    content: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
  navigate: PropTypes.shape({
    header: PropTypes.bool,
    menu: PropTypes.bool,
    filter: PropTypes.bool,
    footer: PropTypes.bool,
  }),
};

export default Page;
