import { lobbyApiPost, translationApiGet } from '../../api';
import { baseApiGet } from '../../api/baseApi';
import {
  SET_PAGE_METADATA,
  GET_WORDS_ERROR,
  SET_WORDS,
  SET_LOCAL_WORDS,
  UPDATE_DATA,
  FETCH_DATA_ERROR,
  GET_WINDOW_SIZE,
  GET_INFO,
  SET_INFO,
  GET_INFO_ERROR,
  GET_IP_ERROR,
  GET_IP_SUCCESS,
  GET_USERAGENT_SUCCESS,
  SET_ACTIVE_INPUT,
  SET_ACTIVE_FORM,
  SIGNUP_TYPE,
  SIGNUP_TYPE_SUCCESS,
  SIGNUP_TYPE_ERROR,
  BONUS_DETAILS_INFO,
  SIGNUP_TYPE_ACTIVATION,
  SIGNUP_PHONE_NUMBER,
  ACCOUNT_UPDATE_INFO,
  SET_VERIVICATION,
  GET_VERIVICATION_ERROR,
} from './actionTypes';

export const fetchData = (page) => {
  return async (dispatch) => {
    const token = localStorage.getItem('user-token');
    const agent = localStorage.getItem('user-agent');
    const ip = localStorage.getItem('user-ip');
    const isFirstVisit = localStorage.getItem('user-first-visit');
    const key = localStorage.getItem('cmd-key');

    const params = {
      key,
      page,
      ...(token ? { token } : {}),
      cmd: 'landing',
    };

    if (isFirstVisit !== '0') {
      await Object.assign(params, { info: { agent, ip } });
    }

    const response = await lobbyApiPost({ params });

    const data = await response;

    if (data.content?.token) {
      localStorage.setItem('user-token', data.content.token);
    }

    if (data.error) {
      dispatch({
        type: FETCH_DATA_ERROR,
        payload: { error: data.error },
      });
    }

    if (page?.includes('/bonuses/')) {
      dispatch({
        type: BONUS_DETAILS_INFO,
        payload: data.content,
      });
    }

    dispatch({
      type: SET_PAGE_METADATA,
      payload: data.content.page?.info,
    });

    dispatch({
      type: UPDATE_DATA,
      payload: data.content,
    });
  };
};

export function loadLocalTranslations(lang) {
  const translationFilePath = `/lang/${lang}/translation.json`;
  return fetch(translationFilePath)
    .then((response) => {
      return response.json();
    })
    .catch();
}

export function getWords(lang) {
  return async (dispatch) => {
    if (!lang) return;

    try {
      const [translationApiResponse, localTranslations] = await Promise.all([translationApiGet({ params: { translation: lang } }), loadLocalTranslations(lang)]);

      const translationData = await translationApiResponse;
      if (translationData?.error) {
        dispatch({
          type: GET_WORDS_ERROR,
          payload: translationData.error,
        });
      }

      if (translationData.content) {
        dispatch({
          type: SET_WORDS,
          payload: translationData.content,
        });

        dispatch({
          type: SET_LOCAL_WORDS,
          payload: localTranslations,
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error loading translations:', error);
    }
  };
}

export function getWindowSize(size) {
  return {
    type: GET_WINDOW_SIZE,
    payload: size,
  };
}

export const signUpRequest = ({ form, social, domain }) => {
  return async (dispatch) => {
    dispatch({ type: SIGNUP_TYPE });
    const key = localStorage.getItem('cmd-key');
    const agent = localStorage.getItem('user-agent');
    const ip = localStorage.getItem('user-ip');
    const params = {
      cmd: 'signUp',
      info: { agent, ip },
      data: { key: !social ? key : null },
      domain: domain,
    };

    if (!social && form.hasOwnProperty('phone')) {
      dispatch({
        type: SIGNUP_PHONE_NUMBER,
        payload: form?.phone,
      });
    }

    if (!social && form) {
      params.data = { ...params.data, ...form };
    }

    if (social && !form) {
      params.social = {
        network: social.provider,
        token: social.token,
      };
    }
    const response = await lobbyApiPost({ params });

    const data = await response;

    if (data.content.token) {
      localStorage.setItem('user-token', data.content.token);
      dispatch({
        type: SIGNUP_TYPE_SUCCESS,
        payload: data.content.token,
      });
    }

    if (data.content.activation) {
      dispatch({
        type: SIGNUP_TYPE_ACTIVATION,
        payload: data.content,
      });
    }
    if (data.content.url) {
      window.location.href = data.content.url;
    }

    if (data.error) {
      dispatch({
        type: SIGNUP_TYPE_ERROR,
        payload: data.error,
      });

      setTimeout(() => {
        dispatch({
          type: SIGNUP_TYPE_ERROR,
          payload: '',
        });
      }, 4000);
    }

    if (data?.content === null) return;

    return data;
  };
};

export const getInfoRequest = () => {
  return async (dispatch) => {
    dispatch({ type: GET_INFO });
    const token = localStorage.getItem('user-token');
    const params = {
      cmd: 'info',
      token,
    };

    const response = await lobbyApiPost({ params });

    const data = await response;

    if (data.error) {
      dispatch({
        type: GET_INFO_ERROR,
        payload: data.content.error,
      });
    }

    dispatch({
      type: SET_INFO,
      payload: data.content,
    });

    dispatch({
      type: ACCOUNT_UPDATE_INFO,
      payload: data.content,
    });
  };
};

export const getYourIpRequest = () => {
  return async (dispatch) => {
    if (localStorage.getItem('user-first-visit') !== '1') return;
    const response = await baseApiGet({ api: 'https://api.ipify.org', params: { format: 'json' } });

    const data = await response;
    if (data?.error) {
      dispatch({
        type: GET_IP_ERROR,
        payload: data.error.message,
      });
    }

    localStorage.setItem('user-ip', data.ip);
    dispatch({
      type: GET_IP_SUCCESS,
      payload: data.ip,
    });
  };
};

export function getUserAgent(userAgent) {
  localStorage.setItem('user-agent', userAgent);
  return {
    type: GET_USERAGENT_SUCCESS,
    payload: userAgent,
  };
}

export const setActiveInput = (ref) => {
  return { type: SET_ACTIVE_INPUT, payload: ref };
};

export const setActiveForm = (form, forgot) => {
  return { type: SET_ACTIVE_FORM, payload: { activeForm: form, forgot } };
};

export const getVerificationRequest = ({ cmd, value, code, phone, domain }) => {
  return async (dispatch) => {
    const token = localStorage.getItem('user-token');

    const params = {
      cmd,
      token,
      data: {
        ...(value ? { value } : {}),
        ...(code ? { code } : {}),
        ...(phone ? { phone } : {}),
      },
      domain: domain,
      key: localStorage.getItem('cmd-key'),
    };

    const response = await lobbyApiPost({ params });

    const responseData = await response;

    if (responseData.content) {
      dispatch({
        type: SET_VERIVICATION,
        payload: responseData.content,
      });
    } else {
      dispatch({
        type: GET_VERIVICATION_ERROR,
        payload: 'Verification request failed',
      });
    }

    if (responseData.content.url) window.location.href = responseData.content.url;

    return responseData;
  };
};
