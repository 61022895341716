// reducers/apiReducer.js

import { ACCOUNT_UPDATE_INFO, BONUS_DETAILS_INFO, FETCH_DATA, FETCH_DATA_ERROR, UPDATE_DATA } from '../actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  errorUpdatePass: null,
  account: {
    id: null,
    notification: [],
  },
  bonusDetailsInfo: null,
};

const apiReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case ACCOUNT_UPDATE_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_DATA:
      return {
        ...state,
        ...action.payload,
        account: {
          ...action.payload?.account,
          notification: action.payload?.account?.notification,
        },
        loading: false,
      };
    case FETCH_DATA_ERROR:
      return {
        ...state,
      };
    case BONUS_DETAILS_INFO:
      return {
        ...state,
        bonusDetailsInfo: action.payload,
      };
    // Другие обработчики действий...
    default:
      return state;
  }
};

export default apiReducer;
