import { combineReducers } from 'redux';
import apiReducer from './apiReducer';
import metaReducer from './metaReducer';
import wordsReducer from './wordsReducer';
import settingsReducer from './settingsReducer';
import authReducer from './authReducer';
import handlingReducer from './handlingReducer';

const rootReducer = combineReducers({
  meta: metaReducer,
  api: apiReducer,
  words: wordsReducer,
  settings: settingsReducer,
  auth: authReducer,
  handling: handlingReducer,
});

export default rootReducer;
