import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './ButtonTypesStyles';

export const ButtonTypes = ({ styled = '', location, children, onClick, className = '' }) => {
  return (
    <Button className={`btn btn--${styled} btn--${location} ${className}`} data-attr={location} onClick={onClick} styled={styled}>
      {children}
    </Button>
  );
};

ButtonTypes.propTypes = {
  styled: PropTypes.string,
  'data-location': PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

ButtonTypes.defaultProps = {
  styled: 'rounded',
  'data-location': null,
};
