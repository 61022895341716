import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactInlineSvg from 'react-inlinesvg';

import classNames from 'classnames';

import { Tab, ButtonTypes, ButtonsEl } from '../../../elements';
import { AnimatedPreloader, Preloader } from '../../../components';

import { getYourIpRequest, setActiveForm, signUpRequest } from '../../../store/actions';
import useQuery from '../../../hooks/useQuery';

import { Error, FieldInput, BlockButton } from '../assets/FormStyles';
import { useIpUser } from '../../../hooks';
import { registrationSchema } from '../../../schemas/registrationSchema';
import { ControllerForm } from '../../../elements/ControllerForm';
import { translateField } from '../../../shared/utils';
import { Loader } from '../assets/FormStyles';

export const SignUpForm = () => {
  const dispatch = useDispatch();

  const words = useSelector((state) => state.words);

  const domain = window.location.hostname;
  const query = useQuery();

  const { ipInfo } = useIpUser();

  const key = query.get('key');
  const data = useSelector((state) => state.api);
  const values = useSelector((state) => state.api?.registration?.values);

  const {
    control,
    watch,
    formState: { errors },
    reset,
    getValues,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(registrationSchema),
    context: { values },
    mode: data?.registration?.type === 'emaiOrPhone' ? 'onChange' : 'onSubmit',
  });
  const watchAllFields = watch();

  const signUpError = useSelector((state) => state.auth.signUpError);
  const navigate = useNavigate();
  const [preloader, setPreloader] = useState(false);
  const [form, setForm] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [type, setType] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  const handleChange = useCallback(
    (e) => {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    },
    [form],
  );

  const handleSignUp = useCallback(async () => {
    setPreloader(true);
    await dispatch(getYourIpRequest());

    await dispatch(signUpRequest({ form: { ...form, ...getValues() }, domain: domain })).then((res) => {
      setPreloader(false);
      if (res.content.token) {
        navigate('/');
        reset();
      }
      if (!res.error) dispatch(setActiveForm('success'));
    });
  }, [dispatch, getValues, domain, navigate, reset, setPreloader, form]);

  const getFormVal = useCallback(
    (type, value) => {
      setError(false);
      setForm({ ...form, [type]: value });
    },
    [form],
  );

  const codeChange = useCallback(
    (isvalid, value) => {
      setError(false);
      getFormVal('dialCode', value.dialCode);
      setForm({ ...form, phone: value });
    },
    [getFormVal, form],
  );

  const numberChange = useCallback(
    (isvalid, value, event) => {
      setError(false);
      getFormVal('phone', event.dialCode + value);
      let type = value && value.length > 0 && value !== '' ? 'phone' : false;
      setType(type);
    },
    [getFormVal],
  );
  const handleBack = () => {
    dispatch(setActiveForm('login'));
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index) => {
    setActiveTab(index);
    reset();
  };

  useEffect(() => {
    reset();
  }, [reset, activeTab]);

  const formBlock = useMemo(() => {
    return data.registration?.values.map((item) => {
      switch (item.id) {
        case 'phone':
          if ((activeTab === 1 && data?.registration?.type === 'emaiOrPhone') || data?.registration?.type === 'landing') {
            return (
              <FieldInput className="auth-form_field" key={item.id}>
                <ControllerForm
                  required={item.require !== '0'}
                  placeholder={translateField(item.id, 'auth.input', words, false)}
                  defaultCountry={ipInfo?.country?.toLowerCase() || 'us'}
                  DialCodePreview={ipInfo?.country}
                  forceDialCode
                  fieldType={'phoneInput'}
                  control={control}
                  errors={errors}
                  name={item.id}
                  onChange={numberChange}
                  onChangeCountry={codeChange}
                />
              </FieldInput>
            );
          } else return null;
        case 'email':
          if ((activeTab === 0 && data?.registration?.type === 'emaiOrPhone') || data?.registration?.type === 'landing') {
            return (
              <FieldInput className="auth-form_field" key={item.id}>
                <ControllerForm
                  required={item.require !== '0'}
                  placeholder={translateField(item.id, 'auth.input', words, false)}
                  className="InputPassword"
                  classNameInput="input"
                  control={control}
                  errors={errors}
                  name={item.id}
                />
              </FieldInput>
            );
          } else return null;
        case 'name':
          return (
            <FieldInput className="auth-form_field" key={item.id}>
              <ControllerForm
                required={item.require !== '0'}
                placeholder={translateField(item.id, 'auth.input', words, false)}
                className="InputPassword"
                classNameInput="input"
                control={control}
                errors={errors}
                onChange={handleChange}
                name={item.id}
              />
            </FieldInput>
          );
        case 'password':
          return (
            <FieldInput className="auth-form_field" key={item.id}>
              <ControllerForm
                placeholder={translateField(item.id, 'auth.input', words, false)}
                required={item.require !== '0'}
                className="InputPassword"
                classNameInput="input"
                control={control}
                errors={errors}
                name={item.id}
                fieldType="inputPassword"
              />
            </FieldInput>
          );
        case 'promoCode':
          return (
            !key && (
              <FieldInput className="auth-form_field" key={item.id}>
                <ControllerForm
                  required={item.require !== '0'}
                  placeholder={`${translateField('promo_code', 'auth.input', words, false)} ${item.require !== '0' ? '(Required)' : '(Optional)'} `}
                  className="InputPassword"
                  classNameInput="input"
                  control={control}
                  errors={errors}
                  name={item.id}
                  signUpError={signUpError}
                />
              </FieldInput>
            )
          );
        case 'currency':
          return (
            <FieldInput className="auth-form_field" key={item.id}>
              <ControllerForm
                required={item.require !== '0'}
                placeholder={translateField(item.id, 'auth.input', words, false)}
                fieldType="select"
                selectOptions={item.options}
                className="InputPassword"
                control={control}
                errors={errors}
                name={item.id}
              />
            </FieldInput>
          );
        default:
          return (
            <FieldInput className="auth-form_field" key={item.id}>
              <ControllerForm
                required={item.require !== '0'}
                placeholder={translateField(item.id, 'auth.input', words, false)}
                className="InputPassword"
                classNameInput="input"
                control={control}
                errors={errors}
                onChange={handleChange}
                name={item.id}
              />
              {signUpError && <Error className="error">{signUpError[item.id]?.split('_').join(' ')}</Error>}
            </FieldInput>
          );
      }
    });
  }, [codeChange, control, data?.registration?.values, errors, key, numberChange, signUpError, ipInfo, handleChange, words, activeTab, data?.registration?.type]);

  const renderFormContent = useMemo(() => {
    return (
      <div className="auth-form_wrapper">
        <form onSubmit={handleSubmit(handleSignUp)}>
          {formBlock}
          <BlockButton className="auth-form_buttons">
            <ButtonsEl
              type="submit"
              disabled={!Object.values(watchAllFields).some((item) => !!item)}
              className={Object.values(watchAllFields).some((item) => !!item) ? 'submit-button' : 'submit-button submit-button-disabled'}
              onClick={data?.registration?.type === 'emaiOrPhone' && activeTab === 1 ? handleSubmit(handleSignUp) : null}
            >
              {translateField('registration', 'auth.button', words, false)}
            </ButtonsEl>
          </BlockButton>
        </form>
        {signUpError && (
          <>
            {typeof signUpError === 'string' ? (
              <Error className="error">{translateField(signUpError, 'auth.error', words, false)}</Error>
            ) : (
              Object.keys(signUpError).map((errorKey) => {
                const registrationValueIds = data.registration.values.map((value) => value.id);
                const shouldDisplayError = registrationValueIds.includes(errorKey);
                const errorMessage = signUpError[errorKey];

                return shouldDisplayError ? (
                  <Error key={errorKey} className="error">
                    {errorKey} {translateField(errorMessage, 'auth.error', words, false)}
                  </Error>
                ) : null;
              })
            )}
          </>
        )}
      </div>
    );
  }, [data, formBlock, handleSignUp, handleSubmit, signUpError, watchAllFields, words, activeTab]);

  const reorderedTabs = [
    {
      id: 0,
      key: 'reg_from_email',
      name: translateField('reg_from_email', 'auth.button', words),
      comp: renderFormContent,
    },
    {
      id: 1,
      key: 'reg_from_phone',
      name: translateField('reg_from_phone', 'auth.button', words),
      comp: renderFormContent,
    },
  ];

  if (!ipInfo)
    return (
      <Loader className="auth-form loader-form">
        <AnimatedPreloader />
      </Loader>
    );

  return (
    <div className="auth-form">
      {preloader && <Preloader />}
      <div className="auth-form_title">{translateField('registration', 'auth', words, false)}</div>
      <Tab
        tabs={reorderedTabs}
        activeTab={activeTab}
        onTabChange={handleTabChange}
        className={classNames('auth-form-tabs', {
          'hide-nav': data?.registration?.type !== 'emaiOrPhone',
        })}
        navIsSlider={false}
      />
      <div className="handle-back-in-form">
        <ButtonTypes styled="rounded" location={'back-history'} onClick={handleBack}>
          <ReactInlineSvg className="image arrow-left" src="/images/icons/arrow__left.svg" desciption="arrow" />
          <span>{translateField('back', 'basic', words)}</span>
        </ButtonTypes>
      </div>
    </div>
  );
};
