import { SET_PAGE_METADATA, UPDATE_DATA } from '../actions/actionTypes';

const initialState = {
  title: '',
  description: '',
  keywords: '',
  content: '',
};

const metadataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_METADATA:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_DATA:
      return {
        ...state,
        ...action.payload.page?.info,
      };
    default:
      return state;
  }
};

export default metadataReducer;
