import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLocation } from 'react-router-dom';
import { Navigation } from 'swiper/modules';

export const Tab = ({
  tabs,
  activeTab,
  activeWallet,
  onTabChange,
  className = '',
  styled = '',
  navIsSlider = {
    slidesPerView: 'auto',
    // slidesPerView: 'auto',
    direction: 'horizontal',
    borderBottom: false,
    arrow: true,
    arrowRow: false,
    spaceBetween: '',
  },
}) => {
  const size = useSelector((state) => state.handling.size);
  const location = useLocation();
  const [swiperInstance, setSwiperInstance] = useState(null);
  const swiperRef = useRef(null);

  // useEffect(() => {
  //   if (activeWallet !== activeTab) {
  //     document.addEventListener('click', onTabChange(Number(activeWallet)));
  //   }

  //   return () => {
  //     document.removeEventListener('click', onTabChange(Number(activeWallet)));
  //     location.state = { TabsProfileGeneral: '' };
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [activeWallet]);

  useEffect(() => {
    if (activeWallet) {
      onTabChange(Number(activeWallet));
      location.state = { TabsProfileGeneral: undefined };
    }
  }, [activeTab, activeWallet, location, onTabChange]);
  const swiperSettings = useMemo(() => {
    return {
      slidesPerView: `${navIsSlider.slidesPerView}`,
      initialSlide: activeTab,
      autoHeight: false,
      height: 'auto',

      spaceBetween: navIsSlider.spaceBetween !== undefined ? navIsSlider.spaceBetween : size.mobile ? 10 : 20,
      speed: 1000,
      modules: [Navigation],
      direction: navIsSlider.direction,

      navigation: {
        disabledClass: 'disable',
        nextEl: `${navIsSlider.nextEl ? `.${navIsSlider.nextEl}` : ''}`,
        prevEl: `${navIsSlider.prevEl ? `.${navIsSlider.prevEl}` : ''}`,
      },
    };
  }, [activeTab, size, navIsSlider]);

  useEffect(() => {
    if (swiperInstance && swiperRef.current) {
      swiperInstance.slideTo(activeTab);
    }
  }, [activeTab, swiperInstance]);

  const navigationSlider = useMemo(() => {
    return (
      <div
        className={classNames('tab__nav', {
          'border-bottom': navIsSlider.borderBottom,
          'nav-is-slider': navIsSlider.arrowRow,
        })}
      >
        {navIsSlider.arrow && (
          <div className="swiper-nav">
            <img
              className={classNames('swiper-arrow swiper-arrow-prev', {
                [navIsSlider.prevEl]: navIsSlider.prevEl,
              })}
              src="/images/icons/arrow.svg"
              style={{ width: '20px', height: '20px' }}
              alt="next"
            />
          </div>
        )}

        <Swiper
          {...swiperSettings}
          className={classNames('tab__swiper', {
            general: styled === 'general',
            [styled]: styled,
          })}
          onSwiper={(swiper) => {
            setSwiperInstance(swiper);
            swiperRef.current = swiper;
          }}
        >
          {tabs.map((tab, index) => (
            <SwiperSlide
              key={tab.id}
              className={classNames('name', {
                active: index === activeTab,
              })}
              onClick={() => onTabChange(index)} // Handle tab click to set the active tab
            >
              {tab.name}
            </SwiperSlide>
          ))}
        </Swiper>

        {navIsSlider.arrow && (
          <div className="swiper-nav">
            <img
              className={classNames('swiper-arrow swiper-arrow-next', {
                [navIsSlider.nextEl]: navIsSlider.nextEl,
              })}
              src="/images/icons/arrow.svg"
              style={{ width: '20px', height: '20px', transform: 'rotate(180deg)' }}
              alt="next"
            />
          </div>
        )}
      </div>
    );
  }, [activeTab, onTabChange, styled, swiperSettings, tabs, navIsSlider]);

  const navigationDefault = useMemo(() => {
    return (
      <div
        className={classNames('tab__nav', {
          general: styled === 'general',
          [styled]: styled,
        })}
      >
        {tabs.map((tab, index) => (
          <div
            key={tab.id}
            className={classNames('name', {
              active: index === activeTab,
            })}
            onClick={() => onTabChange(index)}
          >
            {tab.name}
          </div>
        ))}
      </div>
    );
  }, [activeTab, onTabChange, styled, tabs]);

  return (
    <div className={`tab ${className}`}>
      {navIsSlider ? navigationSlider : navigationDefault}
      <div className="tab__content">{tabs[activeTab]?.comp}</div>
    </div>
  );
};
