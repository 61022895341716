import { GET_IP_SUCCESS, GET_IP_ERROR, GET_USERAGENT_SUCCESS, SET_ACTIVE_FORM, SIGNUP_TYPE_ERROR, SIGNUP_TYPE_ACTIVATION, SIGNUP_PHONE_NUMBER } from '../actions/actionTypes';

const initialState = {
  userData: null,
  userError: null,
  signUpError: null,
  signUpActive: null,
  ip: null,
  getIpError: null,
  userAgent: null,
  activeForm: null,
  forgot: null,
  resetPassword: null,
  resetPasswordError: null,
  verifyError: null,
  regPhoneNumber: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IP_SUCCESS:
      return {
        ...state,
        ip: action.payload,
      };
    case GET_IP_ERROR:
      return {
        ...state,
        getIpError: action.payload,
      };
    case GET_USERAGENT_SUCCESS:
      return {
        ...state,
        userAgent: action.payload,
      };
    case SIGNUP_PHONE_NUMBER:
      return {
        ...state,
        regPhoneNumber: action.payload,
      };
    case SIGNUP_TYPE_ACTIVATION:
      return {
        ...state,
        signUpActive: action.payload,
      };
    case SIGNUP_TYPE_ERROR:
      return {
        ...state,
        signUpError: action.payload,
      };
    case SET_ACTIVE_FORM:
      return {
        ...state,
        activeForm: action.payload?.activeForm,
        forgot: action.payload?.forgot,
      };
    default:
      return state;
  }
};

export default authReducer;
