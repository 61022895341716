import React from 'react';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { Checkbox, DatePicker, Input, Select } from 'antd';

import { PhoneInput } from 'react-international-phone';
import { translateField } from '../../shared/utils';
import { Error } from './ControllerFormStyles';
import './ControllerForm.scss';
import 'react-international-phone/style.css';

export const ControllerForm = ({
  name,
  placeholder,
  fieldType = 'input',
  errors,
  control,
  className,
  classNameInput,
  defaultCountry,
  defaultValue,
  disabled,
  selectOptions,
  forceDialCode,
  dialCodePreview,
  disableDialCodeAndPrefix,
  showDisabledDialCodeAndPrefix,
  words,
  picker,
  format,
  children,
  required = false,
  signUpError,
}) => {
  const hasField = {
    input: (field) => <Input disabled={disabled} defaultValue={defaultValue} placeholder={placeholder} className={classNameInput} {...field} />,
    inputPassword: (field) => <Input.Password disabled={disabled} defaultValue={defaultValue} placeholder={placeholder} className={classNameInput} {...field} />,
    checkbox: (field) => (
      <section>
        <Checkbox
          disabled={disabled}
          placeholder={placeholder}
          checked={field?.value}
          onChange={(e) => {
            field?.onChange(e.target.checked);
          }}
        />
        I am currently working in this role
      </section>
    ),
    datePicker: (field) => (
      <DatePicker
        disabled={disabled}
        placeholder={placeholder}
        picker={picker}
        format={format}
        value={field?.value}
        onChange={(value) => {
          field?.onChange(value);
        }}
      />
    ),
    select: (field) => (
      <Select disabled={disabled} placeholder={placeholder} onChange={(value) => field?.onChange(value)} defaultValue={field?.value ?? defaultValue}>
        {selectOptions.map((item, id) => (
          <Select.Option key={id} value={item}>
            {words ? words[item] : item}
          </Select.Option>
        ))}
      </Select>
    ),
    phoneInput: (field) => (
      <PhoneInput
        disabled={disabled}
        showDisabledDialCodeAndPrefix={showDisabledDialCodeAndPrefix}
        disableDialCodeAndPrefix={disableDialCodeAndPrefix}
        forceDialCode={forceDialCode}
        DialCodePreview={dialCodePreview}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={classNameInput}
        defaultCountry={defaultCountry}
        {...field}
      />
    ),
  };

  const translate = useSelector((state) => state.words);
  return (
    <>
      {required && signUpError && <Error className={'required-field'}>* Required field</Error>}
      <section className={className}>
        <Controller noRef name={name} control={control} render={({ field }) => hasField[fieldType](field)} />
        {children}
      </section>
      {errors && fieldType !== 'phoneInput' && errors[name]?.message && <Error className="errors">{translateField(errors[name]?.message, 'auth.error', translate, false)}</Error>}
    </>
  );
};
