import React from 'react';
import classNames from 'classnames';
import { Line } from './Line';

export const ChestList = ({ prizeNumber, moving, setMoving, setAttemptCount }) => {
  const chestNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className="chest_select-section__footer__chest_list">
      {chestNumbers.map((number) => {
        return (
          <div
            key={number}
            className={classNames('chest_select-section__footer__chest_list__item', {
              selected: prizeNumber === number && !moving,
            })}
          >
            <img src={`/images/chest_select/chest_${number}.png`} alt={`chest_${number}`} />
          </div>
        );
      })}
      <Line
        moving={moving}
        duration={4}
        prizeNumber={prizeNumber}
        onMoveEnd={() => {
          setMoving(false);
          setAttemptCount((prev) => prev - 1);
        }}
      />
    </div>
  );
};
